import { User } from '../types'
import { pushEventToGTM } from './gtm'

export type EventType =
  | typeof SUM_OF_STATUS_SETTING
  | typeof SUM_OF_CONTACT_PERSON_SETTING
  | typeof SUM_OF_FIRST_INTERVIEW_DATE_SETTING
  | typeof SUM_OF_SOURCING_ROUTE_SETTING
  | typeof SUM_OF_MYLABEL_SETTING
  | typeof STATUS_SETTING
  | typeof CONTACT_PERSON_SETTING
  | typeof STATUS_SETTING_ON_CAMPANY_PAGE
  | typeof CONTACT_PERSON_SETTING_ON_COMPANY_PAGE
  | typeof STATUS_SETTING_ON_STATUS_PANEL
  | typeof CONTACT_PERSON_SETTING_ON_STATUS_PANEL
  | typeof SOURCING_ROUTE_SETTING_ON_STATUS_PANEL
  | typeof MEMO_EDITING
  | typeof SAVED_COLUMN_SETTING
  | typeof COMPANY_DOWNLOAD_REQUEST
  | typeof COMPANIES_DOWNLOAD
  | typeof INVESTOR_PORTFOLIO_DOWNLOAD
  | typeof ERROR

export const SUM_OF_STATUS_SETTING = '(合算)ステータス設定・更新・削除の数'

export const SUM_OF_CONTACT_PERSON_SETTING = '(合算)担当者設定・更新・削除の数'

export const SUM_OF_FIRST_INTERVIEW_DATE_SETTING = '(合算)初回面談日の編集数'

export const SUM_OF_SOURCING_ROUTE_SETTING = '(合算)ソーシングルートの編集数'

export const SUM_OF_MYLABEL_SETTING = '(合算)マイラベルの編集数'

export const STATUS_SETTING =
  '条件から探すページ：ステータス設定・更新・削除の数'

export const CONTACT_PERSON_SETTING =
  '条件から探すページ：担当者設定・更新・削除の数'

export const STATUS_SETTING_ON_CAMPANY_PAGE =
  '企業ページ：ステータス設定・更新・削除の数'

export const CONTACT_PERSON_SETTING_ON_COMPANY_PAGE =
  '企業ページ：担当者設定・更新・削除の数'

export const STATUS_SETTING_ON_STATUS_PANEL =
  'ステータスパネル：ステータス設定・更新・削除の数'

export const CONTACT_PERSON_SETTING_ON_STATUS_PANEL =
  'ステータスパネル：担当者設定・更新・削除の数'

export const SOURCING_ROUTE_SETTING_ON_STATUS_PANEL =
  'ステータスパネル：ソーシングルート設定・更新・削除の数'

export const MEMO_EDITING = 'メモ編集の数'

export const SAVED_COLUMN_SETTING = '保存されていたステータス一覧カラム設定'

export const COMPANY_DOWNLOAD_REQUEST = '企業個別のダウンロードリクエスト'

export const COMPANIES_DOWNLOAD = '企業一覧のダウンロード'

export const INVESTOR_PORTFOLIO_DOWNLOAD = '投資家の投資先のダウンロード'

export const ERROR = 'エラー'

export const trackEvent = (user: User, eventName: EventType) => {
  pushEventToGTM(user, eventName)
}
